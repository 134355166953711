*{
    padding: 0;
    margin: 0; 
}  
.main_Career {
   /* border: 2px solid red;  */
  min-height: 20vh; 
  padding: 10px;
}  
.text_Career  { 
    display: flex;
    justify-content: center; 
    margin-top: 5px; 

} 
.text_Career > p {
    border-bottom: 4px solid rgb(3, 255, 45); 
    font-size: 30px;
    font-weight: 600;
    color: #006241; 
}
.career_content {
     
    min-height: 260px; 
    padding: 10px; 
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;  
   
   
    
} 
.career_content > .box { 
    padding: 10px 25px;
    margin: 10px;
    box-shadow: rgba(0, 0, 0, 0.30) 0px 5px 15px;  
    
    background-color: #f4f7f9;
    width: 20rem;
    border-radius: 16px;
} 

.career_content > .box > .title_date{ 
    margin-top: 20px;
    /* border: 2px solid red; */
    display: flex; 
    align-items: center;
    justify-content: space-between;
} 
.career_content > .box > .title_date > p { 

    /* border: 2px solid red; */
     font-weight: 500;  
     color:  green;
     font-size: 10px;

} 
.career_content > .box > .title_date > span{ 
    font-size: 20px;
    font-weight: 500; 


} 
 .quick_status { 
    margin-top: 10px;
    display: flex; 
    justify-content: space-evenly;
 } 
 .quick_status > p {   
    background-color: #006241;
    border: 3px solid green;  
    color: #fff;
    border-radius: 15px;
    padding: 5px;
    font-size: 12px;

 } 
 .description {
    margin-top: 25px; 
    margin-bottom: 20px;
 } 
 .description > p {
font-weight: 500; 

 }