@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600&display=swap');  

*{ 
    
    padding: 0;
    margin: 0;
}
.hero_Section {
    margin-top: 55px;
    height: 70vh; 
    background-color: blueviolet;  
}

.heroimg {   
    width: 100%; 
    height:100%;
    background-image: url('../../../images/AdobeStock_485469732.jpeg');
    background-repeat: no-repeat; 
    background-size:cover;
    background-position: center;     
} 
.heroimg > .layone {
    /* border: 2px solid red; */
    height: auto; 
    display: flex;
    justify-content: flex-end;
}
.layone > .laytwo {
     /* border: 2px solid green;  */

    height: auto ;
    width: 40%;
}
.layone > .laytwo > .hero_cont  { 
    color: red;  
    height: 69vh;
    /* border: 2px solid red;   */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;


} 
.layone > .laytwo > .hero_cont > p  { 
    color: #fff; 
    font-family: 'Poppins', sans-serif; 
    font-weight: 500;
    font-size: 40px;
     

}  
.layone > .laytwo > .hero_cont > span{ 
    font-size: 38px;  
    font-weight: 600;
    color: rgb(3, 255, 45);
} 

@media screen and (max-width: 1210px) { 
    .layone > .laytwo > .hero_cont {
        /* border: 2px solid red;  */
        min-height: 32rem;
        width: auto;
        display: flex;
        justify-content: flex-end;
    }
    .layone > .laytwo > .hero_cont > p  { 
        /* color: red;   */
        display: flex;
        justify-content: flex-end; 
        font-family: 'Poppins', sans-serif; 
        font-weight: 500;
        font-size: 40px;
         
    
    }   
    .heroimg > .layone {
        /* border: 2px solid red;   */
        height: 99%; 
        display: flex;
        justify-content: center; 
        align-items: flex-end;  

    } 
    .layone > .laytwo {
          /* border: 2px solid green;    */

          width: 95%;
   } 
   .heroimg {   
    width: 100%;
    background-image: url('../../../images/AdobeStock_485469732.jpeg');
    background-repeat: no-repeat; 
    background-size:cover;   
    background-position-x: 25%; 
    max-height: fit-content;
    

} 
.layone > .laytwo > .hero_cont > span{  
    font-size: 25px; 
    font-weight: 600;
    color: rgb(3, 255, 45);
}  


} 

@media screen and (max-width: 910px) { 

.layone > .laytwo > .hero_cont > p  { 
    /* color: red;   */
    display: flex;
    justify-content: flex-end; 
    font-family: 'Poppins', sans-serif; 
    font-weight: 500;
    font-size: 35px; 

}   
}