*{
    padding: 0;
    margin: 0;
}
.sectionthree{ 
    /* border: 2px solid red; */
    height: auto;
    padding: 10px; 
    display: flex;  
    justify-content: space-between;
    flex-wrap: wrap;  
    background-color: rgb(245, 239, 239);
}  


.sectionthree > .card{
     /* border: 2px solid red;   */
    width: 40%;   
    height: 75vh; 
    margin: 5px;
    flex-grow: 1; 
    flex-shrink: 1;
}  

.sectionthree > .card > .imgone{   
    border-radius: 6px;
    height: 75vh; 
    background-image: url('../../../images/AdobeStock_302574771.jpeg');
    background-position: center;
    background-size: cover; 
    
} 
.sectionthree > .card > .imgtwo{    
    border-radius: 6px;
   
    height: 75vh; 
    background-image: url('../../../images/AdobeStock_333392980.jpeg');
    background-position: center;
    background-size: cover; 
     
}
.sectionthree > .card > .imgthree{  
    border-radius: 6px;
    height: 75vh; 
    background-image: url('../../../images/AdobeStock_366729438.jpeg');
    background-position: center;
    background-size: cover; 
     
}
.sectionthree > .card > .imgfour{  
    border-radius: 6px;
    height: 75vh; 
    background-image: url('../../../images/AdobeStock_470633962.jpeg');
    background-position: center;
    background-size: cover; 
     
}
.sectionthree > .card > .imgfive{  
    border-radius: 6px;
    height: 75vh; 
    background-image: url('../../../images/AdobeStock_483298609.jpeg');
    background-position: center;
    background-size: cover;  
    background-position-x: 20%;
    
}
.sectionthree > .card > .imgsix{  
    border-radius: 6px;
    height: 75vh; 
    background-image: url('../../../images/AdobeStock_485241252.jpeg');
    background-position: center;
    background-size: cover; 
     
}  




.sectionthree > .card > .imgone    > .main_imgtxt {
    display: flex;
    justify-content: center; 
    /* border: 2px solid red; */
    height: 100%; 
    width: 100%;  
    font-size: 26px; 
    color: #fff; 
    font-weight: 600;
    align-items: center; 
    
    
} 
.sectionthree > .card > .imgone   > .main_imgtxt > .imgtxt { 

   border: 2px solid red;  
   width: auto;  
   background: rgba(23, 9, 9, 0.592);
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    border-radius: 5px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
    padding: 5px; 
    border-top-left-radius: 20px;
} 
.sectionthree > .card > .imgone > .main_imgtxt > .imgtxt > p {
    display: flex;
    justify-content: center; 
    border-bottom:  4px solid rgb(3, 255, 45);
}  


.sectionthree > .card > .imgtwo    > .main_imgtxt {
    display: flex;
    justify-content: center; 
    /* border: 2px solid red; */
    height: 100%; 
    width: 100%;  
    font-size: 26px;  
    
    color: #fff; 
    font-weight: 600;
    align-items: center;
    
} 
.sectionthree > .card > .imgtwo   > .main_imgtxt > .imgtxt {
   border: 2px solid red;  
   width: auto;  
   background: rgba(2, 31, 11, 0.701); 
   border-top-left-radius: 20px;
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    border-radius: 5px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
    padding: 5px; 
    border-top-left-radius: 20px;
} 
.sectionthree > .card > .imgtwo > .main_imgtxt > .imgtxt > p {
    display: flex; 
    border-bottom:  4px solid rgb(3, 255, 45);
    justify-content: center;
} 
 

.sectionthree > .card > .imgthree    > .main_imgtxt {
    display: flex;
    justify-content: center; 
    /* border: 2px solid red; */
    height: 100%; 
    width: 100%;  
    font-size: 23px; 
    color: #fff; 
    font-weight: 600;
    align-items: center;
    
} 
.sectionthree > .card > .imgthree   > .main_imgtxt > .imgtxt {
  
   border-top-left-radius: 20px;
   background: rgba(38, 3, 12, 0.636);
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    border-radius: 5px; 
    border-top-left-radius: 20px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
    padding: 5px;
} 
.sectionthree > .card > .imgthree > .main_imgtxt > .imgtxt > p {
    display: flex;
    justify-content: center; 
    border-bottom:  4px solid rgb(3, 255, 45);
}  


.sectionthree > .card > .imgfour    > .main_imgtxt {
    display: flex;
    justify-content: center; 
    /* border: 2px solid red; */
    height: 100%; 
    width: 100%;  
    font-size: 26px; 
    color: #fff; 
    font-weight: 600;
    align-items: center;
    
} 
.sectionthree > .card > .imgfour   > .main_imgtxt > .imgtxt {
    background: rgba(0, 0, 0, 0.612); 
    border-top-left-radius: 20px;
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    /* border-radius: 5px; */
    /* border: 1px solid rgba( 255, 255, 255, 0.18 ); */
    padding: 5px;
} 
.sectionthree > .card > .imgfour > .main_imgtxt > .imgtxt > p {
    display: flex;
    justify-content: center; 
    border-bottom:  4px solid rgb(3, 255, 45);
} 
 

.sectionthree > .card > .imgfive    > .main_imgtxt {
    display: flex;
    justify-content: center; 
    /* border: 2px solid red; */
    height: 100%; 
    width: 100%;  
    font-size: 22px; 
    color: #fff; 
    font-weight: 600;
    align-items: center;
    
} 
.sectionthree > .card > .imgfive   > .main_imgtxt > .imgtxt {
   border: 2px solid red;  
   border-top-left-radius: 20px; 
   background: rgba(26, 6, 6, 0.432);
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    border-top-right-radius:5px; 

    border: 1px solid rgba( 255, 255, 255, 0.18 );
    padding: 5px;
} 
.sectionthree > .card > .imgfive > .main_imgtxt > .imgtxt > p {
    display: flex;
    justify-content: center; 
    border-bottom:  4px solid rgb(3, 255, 45);
} 


.sectionthree > .card > .imgsix    > .main_imgtxt {
    display: flex;
    justify-content: center; 
    /* border: 2px solid red; */
    height: 100%; 
    width: 100%;  
    font-size: 24px; 
    color: #fff; 
    font-weight: 600;
    align-items: center;
    
}  

.sectionthree > .card > .imgsix   > .main_imgtxt > .imgtxt { 
   border-top-left-radius: 15px;  
   background: rgba(10, 9, 9, 0.47);
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
 
    border: 1px solid rgba( 255, 255, 255, 0.18 );
    padding: 5px; 

} 
.sectionthree > .card > .imgsix > .main_imgtxt > .imgtxt > p {
    display: flex;
    justify-content: center; 
    border-bottom:  4px solid rgb(3, 255, 45);
} 









@media screen and (max-width: 935px) { 
    .sectionthree > .card {
        /* border: 2px solid orange;  */
        width: 40%;  
        height: auto;  
        margin: 10px;
        flex-grow: 1; 
        flex-shrink: 1;
    }



}

@media screen and (max-width: 735px) {
    .sectionthree > .card {
        /* border: 2px solid green;  */
        width: 490px;   
        margin: 10px; 
        height: auto;
        flex-grow: 1; 
        flex-shrink: 1;
    }
}  
 

@media screen and (max-width: 917px) {    
    .sectionthree > .card > .imgfive{  
        border-radius: 6px;
        height: 75vh; 
        background-image: url('../../../images/AdobeStock_483298609.jpeg');
        background-position: center;
        background-size: cover;  
        background-position-x: 15%;
        
    }  
    .sectionthree > .card > .imgthree > .main_imgtxt > .imgtxt > p {
        display: flex;
        justify-content: center; 
        border-bottom:  4px solid rgb(3, 255, 45); 
        font-size: 20px;
    }  


    .sectionthree > .card > .imgfour > .main_imgtxt > .imgtxt > p {
        display: flex;
        justify-content: center; 
        border-bottom:  4px solid rgb(3, 255, 45); 
        font-size: 22px;
    } 



    .sectionthree > .card > .imgfive > .main_imgtxt > .imgtxt > p {
        display: flex;
        justify-content: center; 
        border-bottom:  4px solid rgb(3, 255, 45); 
        font-size: 16px;
    } 


    .sectionthree > .card > .imgsix > .main_imgtxt > .imgtxt > p {
        display: flex;
        justify-content: center; 
        border-bottom:  4px solid rgb(3, 255, 45); 
        font-size: 20px;
    } 

}
