/* .contact {
    height: 70vh;  
     
    margin-top: 50px; 
    
    
} 
.Title_contact { 
    padding: 10px;
    text-align: center;  
    font-size: 18px; 
    font-weight: 500; 
    margin-bottom: 30px;
}
 
.forms{ 
    margin: auto; 
    width:60vw;  
    height: auto;
    border-radius:3px; 
    padding: 5px;
} 

.forms >  .forms_contant > .form_details {
    border-radius: 6px; 
    padding: 10px;       
    background-color: #758bfd; 
    box-shadow: rgba(0, 0, 0, 0.30) 0px 5px 15px; 
    border: 2px solid red;
} 
.forms >  .forms_contant > .form_details > .form_Section{  
    margin: 10px;
    display: flex;
    flex-direction: column; 
    color: #fff;
    gap: 10px; 

} 
.forms >  .forms_contant > .form_details > .form_Section >input { 
    width: 60%;   
    margin: auto;
    height: 30px; 
    border: none; 
    padding: 5px; 
    border-radius: 6px;
    outline: none;
    background-color: rgb(244, 244, 244);

} 
.forms >  .forms_contant > .form_details > .form_Section >input::selection{
    border: 2px solid red;
} */ 
.contact { 
    
    min-height: 68vh;  
    
     
} 
.Title_contact {
     font-size: 23px;
    text-align: center; 
    margin-bottom: 10px;
 
} 
.Title_contact > p { 
    /* font-size: 25px; 
    font-weight: 500; 
    color:  rgb(40, 96, 17);  */ 
     background-color: #006241; 
        box-shadow: rgba(0, 0, 0, 0.3) 0px 5px 15px; 
        margin: auto;
        color: #fff; 
        max-width: 19rem; 
        border-top-left-radius: 60px; 
        border-bottom-right-radius: 60px; 

} 
.contact_form {  

    padding: 15px;
    margin: auto; 
    width: 20rem;  
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.30) 0px 5px 15px;  
    border-radius:10px; 
    height: auto;     

} 
form > .contact_form_details {
    display: flex;  
    flex-direction: column; 
    gap: 2px; 
    margin-bottom: 20px;
} 
.contact >  .contact_form  > form > .contact_form_details > input{ 

    height: 40px;  
    border-radius: 4px;
    border:2px rgb(196, 197, 197) solid ;  
    padding-left:10px ; 
    font-size: 14px; 
    outline-color: #006241; 
    font-weight: 500; 
} 
form > .contact_form_details > label {
    color: #1abd12ef;  
    font-weight: 500;
    font-size: 14px;
} 
form > .contact_form_details_holder {
    display: flex;
    justify-content: space-between;
} 
form > .contact_form_details > textarea{
    height: 80px;  
    border-radius: 4px;
    border:2px rgb(196, 197, 197) solid ;  
    resize: none;
    font-size: 14px;  
    padding: 5px;
    outline-color: #006241; 
    font-weight: 500; 
}  
 
.contact_form_details_button { 
    display: flex;
    justify-content: center; 

} 
.contact_form_details_button  > button {
    padding: 5px; 
    width: 8rem; 
    border-radius:6px; 
    background-color: green; 
    color: #fff;
    font-size: 16px; 
    font-weight: 500;  
    box-shadow: rgba(0, 0, 0, 0.30) 0px 5px 15px;  
    border: none; 
    cursor: pointer;  
    align-items: center;
    outline: none;
} 
.contact_form_details_button  > button:active {
      background-color: #fff;
      color: #000; 
      border: 2px solid royalblue;
} 
.space {
    height: 4vh;
} 
.wrap {     
    background-color: #9bf5b4; 
    padding-top: 30px; 
    margin-bottom: 25px; 
    border-top-left-radius: 325px; 
    border-bottom-right-radius: 325px; 

} 
.bgcc {
    background-color: rgb(7, 51, 3); 
} 



@media screen and (max-width: 543px) {   
    .Title_contact > p {   
        background-color: #006241; 
        box-shadow: rgba(0, 0, 0, 0.3) 0px 5px 15px; 
        margin: auto;
        color: #fff; 
        max-width: 19rem; 
        border-top-left-radius: 60px; 
        border-bottom-right-radius: 60px;       
        /* color:  rgb(100, 111, 238);     */

    }
    


}