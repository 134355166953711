*{
    padding: 0;
    margin: 0;
} 
.footer {  
    border-top: 3px solid rgb(155, 165, 140);
    height: 20vh; 
    margin: auto;
    width: 97%; 
    height: auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    padding-bottom: 10px;
} 
.footer > .text {  
    margin-top: 20px;
    display: flex;  
    width: 50rem; 
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap; 
    padding-bottom:20px; 
}  

.footer > .text > .lay{ 
    width: 120px;   
    margin: 10px;    
    border-right: 3px solid rgb(68, 69, 66);
} 
.footer > .text > .lay > p {
    font-weight: bold; 
    font-size: 13px;
} 
.location {
    font-size: 15px;
    font-weight: 500;
}
 .footer_down{ 
    margin: auto;
    width: 96%; 
    height: auto; 
    padding-bottom: 20px; 
    display: flex;
    justify-content: space-around; 
    flex-wrap: wrap;
    margin: 5px; 
    align-items: center;

 } 
 .footer_down > p {
    font-size: 13px; 
    font-weight: 500; 

 } 
 .footer_reg > P {
    font-size: 14px;
    font-weight: 500;
 } 
 @media screen and (max-width: 917px) {  
    .footer_reg > P {
        font-size: 9px;
    }



 }