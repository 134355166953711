@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600&display=swap');
*{
    padding: 0;
    margin: 0;  
    font-family: 'Poppins', sans-serif; 
}  

.nav_right > li , a {    
    
    list-style: none;  
    text-decoration: none; 
    color: rgb(4, 17, 54);   
    font-size: 15px;
    font-weight: 500; 
    cursor: pointer;
}     

.nav_right > li > a{
    padding: 10px 0px; 
    
     
}   
.nav_right > li > a:hover{
     color: rgb(130, 124, 124);
    
     
}   
.nav_right a.active {
    border-bottom: 4px solid  rgb(3, 255, 45);
}
.menu_hidden {
    display: none;
}
.humber_menu {
    display: none;
}
.nav_right {  
    display: flex;  
    align-items: center; 
    gap: 60px;
    width: 450px;      
    
}  

.nav_cont {    
    position: fixed;
    top: 0; 
    max-height: 70px; 
    width: 100%;
    display: flex;  
    justify-content: space-between;   
    padding: 8px 0px;
    align-items: center; 
    gap: 60px;    
    background-color: #fff;
    /* box-shadow: rgba(0, 0, 0, 0.30) 0px 5px 15px;  
    backdrop-filter: blur(4rem);   */
}     

.logo_and_title { 

    display: flex;   
    width: 350px;   
    align-items: center;
    gap: 10px; 
    padding: 0px 15px;
}    

.com_title {    
    
    margin-left: 20px;
    display: flex; 
    flex-direction: column;
    align-items: center; 
    font-weight: 500;
    font-size: 16px; 
    color:#006241; 
    box-shadow: rgba(100, 100, 111,00....) 0px 7px 29px 0px;
}

.mob{
    display: none;
}
 

/* .logo > h3 { 
     color: red;
     box-shadow: rgba(100, 100, 111,00....) 0px 7px 29px 0px; 
} */ 
 .logo  {   
     width: 15%;  
     object-fit: contain;
     height: 30px;
     display: flex;   
     justify-content: center;
   
  
      
     
}   

.com_title > p{
    color: green; 
    font-family: 'Poppins',sans-serif;
    font-weight: 600;

}   


.com_title > span {
    color: green;
    font-weight: 500;
    font-size: 9px;
} 
.menu {
    display: none;
}
@media screen and (max-width: 878px) {  
    .nav_right {
        display: none;
    }
    .mob{
        position:absolute; 
        top: 46px;  
        left: 0px;
        height: 100vh;  
        width: 100%;   
        display: flex;  
        background: rgb(9, 96, 42);
        flex-direction: column;  
        gap: 30px;   
        align-items: center;  
    }  

    .mob > li {   
        list-style: none;
        text-decoration: none;
    margin-top: 30px;  

    }  
    .mob > li > a {   
        font-size: 18px;  

    }  
    .mob > li > a:active {    
        border-bottom: 4px solid  rgb(3, 255, 45);

    } 
    
    
    .com_title {
        display: none;
    }     
    .menu {
        display: flex;  

    }
    .logo_and_title { 
        display: flex; 
        width: 100%; 
        justify-content: space-between; 
        
    }  
     .logo {
         
        margin-left:40px ;
        width: 200px;
     }
   
    .menu_hidden{
        display: flex; 
        
    } 
    
          
    .mob > li > a{
      color: aliceblue;
    }  
    .mob.close { 
        display: none;  
        
    } 
    .menu{
        cursor: pointer;
    }
     
   /* .MenuIcon {
        transform: rotate(360deg);
        transition: all 0.2s ease-in-out;
    }
    .CloseIcon {
           transform: rotate(180deg); 
           transform: all 0.2s ease-in-out;
    } */

} 

