*{
    padding: 0;
    margin: 0;
} 
.sectiontwo { 
    
    width: 100%;
} 
.main_two  {
    display: flex;
    justify-content: center;
    margin-top: 45px; 
    font-size: 30px;
    font-weight: 600; 
    color: #006241;
    padding-bottom: 25px;
} 

.main_two > .sub_main {
    border-bottom: 4px solid  rgb(3, 255, 45);
}