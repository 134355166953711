*{
    padding: 0;
    margin: 0;
} 
.main_about {    
    margin-top: 3rem;
    padding: 5px; 
    margin-bottom: 3rem; 
}  
.abouttitle > p { 
	margin: auto; 
	width: 130px;
	border-bottom: 4px solid green;
    display: flex;
	justify-content: center; 
    font-size: 25px; 
    font-weight: 500;
    padding-bottom: 1px;

}
/* .inner_about{ 
    border: 2px solid red;
    padding: 10px;
}  */ 

.inner_about > p {
    font-size: 28px; 
	display: flex;

}
.inner_about {
   margin: auto; 
   width: 90%; 
   

}
 .inner_about::before {
		content: "\201C";
	 
		display: flex;
		justify-content: flex-start;
		font-weight: bold;
		font-size: 4em;
		color: green;
		top: -30px;
		left: 0px;
		font-family: Georgia, Times, serif;
	}
.inner_about::after {
		content: "\201D";
		display: flex;
		justify-content: flex-end;
		font-weight: bold;
		font-size: 4em;
		color: green;
		bottom: -50px;
		right: 0px;
		font-family: Georgia, Times, serif;
	} 


	